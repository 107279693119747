import { navigateTo, useRuntimeConfig, defineNuxtRouteMiddleware } from "#app";

export default defineNuxtRouteMiddleware((from) => {
	const origin = useRuntimeConfig().public.auth.computed.origin;
	const loginPath = "/login";

	if (from.path === loginPath && !from.query.callbackUrl) {
		return navigateTo(`${loginPath}?callbackUrl=${origin}`);
	}
});
